@import '/src/utils/sass/mixins';

.prospectusContainer {
	@include flexCenter(column);

	position: relative;
	z-index: 1;
	justify-content: space-between;
	overflow: hidden;
	height: 700px;
	min-height: 700px;
	padding: 50px 0;
	background: #0e0f22;
	color: #000;
	transition: background 1s ease;
}

.headingsContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 90%;
	min-height: 280px;
	margin-bottom: 50px;
}

.textContainer {
	width: 80%;
	min-height: 250px;
	margin-top: 50px;
	line-height: 30px;
	text-align: center;
	-moz-text-align-last: center;
	text-align-last: center;
	opacity: 0;

	h5 {
		margin-bottom: 30px;
	}
}

.heading {
	width: 90%;
	height: 40%;
	margin: 0;
	padding: 0;
	font-weight: 700;
	font-family: $primaryFont;
	transition: all 0.3s;
}

.inactiveHeading {
	opacity: 0.3;
	transition: all 0.3s;
}

.activeHeading {
	opacity: 1;
}

[role='button'] {
	cursor: none !important;
	pointer-events: none !important;
}

.stellarPromiseBtn,
.stellarPromiseBtn:active,
.stellarPromiseBtn:focus {
	margin-bottom: 0;
	padding: 0;
	border: none;
	background: none;
	color: #5a5b5c;
	outline: none;
	font-weight: bold;
	font-size: 20px;
	font-family: $primaryFont !important;
	cursor: none !important;
}

.sectionHeading {
	@include flexCenter(column);

	width: 20vw !important;
	height: 250px;
	padding: 30px 5px 0;
	opacity: 0;
	pointer-events: none;
}

.sectionIconbackground {
	position: absolute;
	right: -100px;
	bottom: -120px;
	z-index: -1;
	width: 600px;
	height: 600px;
	opacity: 0;
	pointer-events: none;
}

@media (max-width: 992px) {
	.headingsContainer {
		width: 90%;
		min-height: 230px;
	}

	.sectionHeading {
		height: 200px;
	}

	.sectionIconbackground {
		right: -90px;
		width: 500px;
		height: 500px;
	}
}

@media (max-width: 768px) {
	.sectionHeading {
		width: 23vw;
		height: 160px;
	}

	.heading {
		font-size: 20px;
	}

	.headingsContainer {
		width: 95%;
		min-height: 240px;
	}

	.sectionIconbackground {
		right: -80px;
		width: 400px;
		height: 400px;
	}
}

@media (max-width: 576px) {
	.sectionHeading {
		width: 35vw !important;
		height: fit-content;
		margin: 0;
		padding: 0 10px;

		h4,
		h5 {
			font-size: 16px !important;
		}
	}

	.headingsContainer {
		flex-wrap: wrap;
		height: 300px;
		padding: 0 30px;
	}

	.textContainer {
		min-height: 450px;
	}
}

@media (max-width: 480px) {
	.textContainer {
		min-height: 480px;
	}
}
