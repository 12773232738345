@import '/src/utils/sass/mixins';

.mainContainerBackground {
	width: 100%;
	height: min-content;
	background: linear-gradient($primary-background, $secondary-background);
}

.headerContainer {
	position: relative;
	z-index: 1;
	width: 100%;
	height: min-content;
	background: $secondary-background;
}

.starsBackground {
	height: 100vh;
	background-image: url('../../images/stars.png');
	background-position: center;
	background-size: cover;
}

.starsMediumHeader,
.starsSmallHeader {
	background-image: url('../../images/stars.png');
	background-position: center;
}

.starsMediumHeader {
	height: 400px;
	background-size: cover;
}

.starsSmallHeader {
	height: 200px;
	background-size: 80%;
}

.shiningStar {
	position: absolute;
	top: 20vh !important;
	right: 20vw !important;
}

.shootingStar {
	position: absolute;
	top: calc(50% - -55px);
	left: calc(50% - 66px);
	height: 2px;
	border-radius: 50%;
	background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
	filter: drop-shadow(0 0 6px rgba(105, 155, 255, 1));
	animation: starTrail 10s ease-in-out infinite, starTranslate 10s ease-in-out infinite;
	animation-delay: 5s;
}

.shootingStar::before,
.shootingStar::after {
	content: '';
	position: absolute;
	right: 0;
	height: 1px;
	border-radius: 100%;
	background: linear-gradient(-45deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
	transform: translateX(50%) rotateZ(45deg);
	animation: starShine 10s ease-in-out infinite;
	animation-delay: 5s;
}

.shootingStar::after {
	transform: translateX(50%) rotateZ(-45deg);
}

:global(#parallaxScene) div:nth-child(2) {
	width: 100%;
}

@keyframes starTrail {
	0%,
	10%,
	100% {
		width: 0;
	}

	3% {
		width: 100px;
	}
}

@keyframes starShine {
	0%,
	10%,
	100% {
		width: 0;
	}

	5% {
		width: 30px;
	}
}

@keyframes starTranslate {
	0% {
		transform: translateX(0);
	}

	10%,
	100% {
		transform: translateX(200px);
	}
}

@media (max-width: 992px) {
	.starsMediumHeader {
		height: 350px;
	}

	.starsSmallHeader {
		background-size: 120%;
	}
}

@media (max-width: 768px) {
	.starsMediumHeader,
	.starsSmallHeader {
		background-size: 150%;
	}

	.starsMediumHeader {
		height: 480px;
	}
}

@media (max-width: 576px) {
	.starsMediumHeader {
		height: 450px;
	}

	.starsSmallHeader {
		background-size: 200%;
	}
}

@media (max-width: 480px) {
	.starsMediumHeader {
		height: 520px;
	}
}

@media (max-width: 396px) {
	.starsMediumHeader {
		height: 570px;
	}

	.starsSmallHeader {
		height: 80px;
	}
}
