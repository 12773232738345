.trees {
	position: relative;
	width: 100vw;
	margin-top: -10vw;
	padding-left: 10px;
}

.treeSectionContent {
	width: 100vw;
	height: 120px;
	margin-top: -8vw;
	padding: 40px;
	background-color: #0e0f22;
	color: #fff;
	text-align: center;
}

@media (min-width: 1200px) {
	.treeSectionContent {
		height: 230px;
	}
}
