@import '/src/utils/sass/mixins';

.projectCardContainer,
.projectCardContainer:hover {
	@include flexCenter;

	color: #5a5b5c;
	text-decoration: none;
	cursor: none !important;
}

.projectDescriptionContainer {
	@include flexCenter;

	align-items: flex-start;
	height: 40%;
	margin-top: 15px;
	pointer-events: none;

	.projectText {
		display: flex;
		overflow: hidden;
		padding: 0 10px;
		font-size: 14px !important;
		text-overflow: ellipsis;
		pointer-events: none;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
	}

	&.halfCard {
		display: none;
	}
}

.projectTagsContainer {
	width: 90%;
	height: 40%;
	padding: 15px 0;
	text-align: left !important;
	transition: opacity 0.2s ease-in-out 0.2s;

	&.halfCard {
		opacity: 1;
	}

	.tagCard {
		margin: 2px;
		padding: 3px 12px;
		border: 1px solid white;
		border-radius: 25px;
		color: white;
		font-size: 11px;
		cursor: none;
	}
}

.cardHoverCTASection {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 10px 0;
	border-radius: 4px 4px 0 0;
	background-color: white;
	transition: transform 0.2s ease-in-out 0.2s;
	transform: translateY(100%);

	.cardHoverCTAText {
		color: #191c40 !important;
		text-decoration: none;
		cursor: none;
	}
}

.cardContainer {
	@include flexCenter(column);

	position: relative;
	z-index: 1;
	overflow: hidden;
	width: 100%;
	margin: 10px;
	padding: 0 15px;
	border-radius: 10px;
	background-color: #fff;
	-webkit-box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	-moz-box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	text-align: center;
	transition: background 0.3s;

	&.fullCard {
		height: 450px;
	}

	&.halfCard {
		height: 220px;
	}

	&:hover {
		.cardHoverCTASection {
			transform: translateY(0);
		}

		.projectTagsContainer {
			&.halfCard {
				opacity: 0;
			}
		}
	}
}

.projectLogoContainer {
	@include flexCenter(column);

	height: 45% !important;

	.projectLogo {
		width: 40%;
		pointer-events: none;
	}
}

.cardCTASection {
	position: absolute;
	bottom: 5px;
	width: 100%;
	padding: 10px 0;

	.cardCTAText {
		color: #5a5b5c;
		font-weight: 600;
		text-decoration: none !important;
		cursor: none;

		&:hover {
			color: #fff;
		}
	}

	&.halfCard {
		display: none;
	}
}
