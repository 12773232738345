@import '/src/utils/sass/mixins.scss';

.mainContainer {
	position: relative;
	width: 100%;
	min-height: 350px;
	background-image: url('../../../../images/stars.png');
	background-attachment: fixed;
	background-size: cover;
	background-repeat: no-repeat;

	.imageContainer {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 100%;

		.planetImage {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.contentContainer {
		@include flexCenter(column);

		position: relative;
		z-index: 20;
		min-height: 280px;
		text-align: center;

		.ctaHeading {
			margin: 0 auto;
			font-weight: bold;

			@media (max-width: 768px) {
				font-size: 22px !important;
			}
		}

		.buildSomethingButton {
			@include secondaryButton();

			@media (max-width: 768px) {
				padding: 10px 30px !important;
			}
		}
	}
}

@include responsive(bigScreen) {
	.mainContainer {
		min-height: 430px;
	}
}
