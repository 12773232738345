body {
	overflow-x: hidden;
	margin: 0;
	font-family: 'Open Sans', sans-serif !important;
	cursor: none !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family:
		source-code-pro,
		Menlo,
		Monaco,
		Consolas,
		'Courier New',
		monospace;
}

p {
	font-size: 16px !important;
}

h2 {
	font-size: 42px !important;
}

h3 {
	font-size: 30px !important;
}

h4 {
	font-size: 24px !important;
}

h5 {
	font-weight: 600;
	font-size: 20px !important;
}

.solutionsSectionCarousel .dot {
	opacity: 0.3 !important;
}

.solutionsSectionCarousel .dot.selected {
	opacity: 1 !important;
}

.solutionsSectionCarousel .control-dots {
	bottom: 40px !important;
}
