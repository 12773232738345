@import '/src/utils/sass/mixins';

.solutionSection {
	@include flexCenter(column);

	position: relative;
	z-index: 1;
	width: 100%;
	height: 100vh;
	min-height: 800px;
	padding: 10px !important;
	background-color: $primary-background;
	color: #fff;
	font-family: $primaryFont !important;
}

.sectionHeading {
	margin-bottom: 50px;
}

.heroImageContainer,
.descriptionContainer,
.listContainer {
	@include flexCenter;

	height: 270px;
}

.heroImage {
	height: 100%;
}

.descriptionContainer {
	flex-direction: column;
}

.descriptionContainer h5 {
	margin-bottom: 20px;
}

.descriptionContainer h4 {
	margin-bottom: 30px;
}

.listContainer ul {
	list-style-image: url('../../../images/solutions/comet-bullet.svg');
}

.listContainer li {
	padding-bottom: 5px;
	padding-left: 10px;
	font-size: 18px;
}

.conveyorBeltContainer {
	position: relative;
	width: 70%;
	margin-top: 80px;
}

.transportCube {
	position: absolute;
	top: 35%;
	left: 3%;
	height: 20px;
	opacity: 1;
	transition: opacity 0.3s;
}

.transportCubeHidden {
	opacity: 0;
}

.enter,
.exitActive {
	opacity: 0;
	transition: opacity 600ms;
}

.enterActive,
.exit {
	opacity: 1;
	transition: opacity 600ms;
}

@media (max-width: 1200px) {
	.heroImageContainer,
	.descriptionContainer,
	.listContainer {
		height: 320px;
	}

	.heroImage {
		height: 70%;
	}
}

@media (max-width: 992px) {
	.heroImageContainer,
	.descriptionContainer,
	.listContainer {
		height: 350px;
	}

	.listContainer li,
	.descriptionContainer p {
		font-size: 16px;
	}

	.descriptionContainer {
		flex: 42% !important;
		max-width: 42% !important;
		padding: 0 !important;
	}

	.transportCube {
		height: 15px;
	}

	.heroImage {
		height: 50%;
	}

	.listContainer,
	.heroImageContainer {
		flex: 0 0 29% !important;
		max-width: 29% !important;
	}
}

@media (max-width: 768px) {
	.sectionHeading {
		margin-bottom: 60px;
	}

	.carousel {
		@include flexCenter;

		width: 100vw;
		height: 270px;
		margin: 150px 0;

		.solutionSlide {
			width: 100vw;
			height: 270px;
			margin: 80px 0 290px;
			pointer-events: all !important;
		}

		li {
			overflow: hidden !important;
		}

		& > div {
			position: relative;
			overflow: visible !important;
			height: 290px;
		}

		& > div > div {
			position: absolute;
			top: -190px;
			height: 570px;
			pointer-events: none !important;
		}
	}

	.carousel > div > ul li {
		margin: 0 10px !important;
		transition: all 0.3s !important;
		transform: scale(1.7);
	}

	.descriptionContainer {
		position: absolute;
		top: 0;
		justify-content: initial;
		overflow: hidden;
		width: 100vw !important;
		max-width: 100vw !important;
		padding: 50px 10vw !important;
		pointer-events: none;
	}

	.heroImageContainer {
		position: absolute;
		top: 260px;
		left: 20px;
		z-index: -1;
		max-width: 50% !important;
		margin: auto;
		opacity: 0.3;
		pointer-events: none;
		transform: scale(1.5);
	}
}
