@import '/src/utils/sass/mixins.scss';

.swiperWrapper {
	position: relative;
	width: 100%;
	height: 100vh;
}

.swiperContainer {
	position: relative;
	overflow: auto;
}

.arrowButtonLeft,
.arrowButtonRight {
	cursor: none !important;
}

.arrowButtonLeft {
	justify-content: flex-start !important;
}

.arrowButtonRight {
	justify-content: flex-end !important;
}
