@import '/src/utils/sass/mixins.scss';

.mainContainer {
	background: $secondary-background;
	color: #fff;
}

.navbar {
	padding-top: 70px;
}

.screenTitle {
	position: absolute;
	flex: 1;
	width: 100%;
	height: 100%;

	.title {
		@include flexCenter();

		height: 100%;
		color: #fff;
		font-family: $primaryFont;
	}
}

.contactFormContainer {
	position: relative;
	width: 100%;
	min-height: 540px;
	background-color: #fff;
	color: #000;
}

.nextButton {
	@include flexCenter;

	position: absolute;
	top: unset;
	right: 10px;
	bottom: 10px !important;
	width: 30px;
	height: 30px;
	border: 0.1px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	font-weight: 600;
	font-size: 12px;
}

.stepsContainer {
	@include flexCenter(column);

	position: absolute;
	top: -80px;
	width: 100%;
	height: 100%;
	text-align: center;
}

.step {
	@include flexCenter(column);

	position: absolute;
	width: 100%;
	text-align: center;
	opacity: 0;
	visibility: hidden;
}

.step:nth-of-type(1) {
	opacity: 1;
	visibility: visible;
}

.categoriesContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 30px;

	.category {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		width: 100px;
		margin: 0 40px;

		.categoryIcon {
			@include flexCenter();

			width: 70px;
			height: 70px;
			margin-bottom: 30px;
			border-radius: 50%;
			background-color: #dedede;
			transition: background-color 0.2s ease-in;

			.categoryIconSvg {
				width: 60%;
				height: 60%;
			}
		}

		&:hover .categoryIcon {
			background-color: $secondary-background;
		}

		&:hover .categoryIconSvg {
			path,
			circle {
				fill: #fff;
			}
		}
	}
}

.input,
.input:active,
.input:focus {
	width: 40% !important;
	margin-bottom: 5px;
	padding: 10px !important;
	border: none;
	border-bottom: 1px solid #cecece;
	background: none;
	outline: none;
	resize: none;
	cursor: none !important;
	transition: all 0.3s;
}

.input:active,
.input:focus {
	border-bottom: 1px solid $primary-background !important;
}

.navigationButtons {
	@include flexCenter();

	position: absolute;
	bottom: 80px;
	flex-wrap: wrap;
	gap: 60px;
}

.nextBtn,
.prevBtn,
.sendBtn {
	@include primaryButton();

	padding: 8px 90px;
	background-color: $secondary-background;
	font-size: 16px;
}

.disabledNextBtn,
.disabledSendBtn {
	border: 1px solid gray !important;
	color: gray !important;
	pointer-events: none !important;
}

.disabledSendBtn {
	background: gray !important;
	color: rgb(14, 15, 34) !important;
}

.nextBtn,
.sendBtn {
	float: right;
}

.prevBtn {
	@include secondaryButton();

	padding: 6px 90px;
}

.stepHeading {
	margin-bottom: 30px;
	padding: 0 20px;
}

.successLottieContainer {
	height: 150px;
}

.envelopeLottieContainer {
	position: absolute;
	right: -180px;
	bottom: -180px;
	width: 500px;
	height: 500px;
	opacity: 25%;
	filter: grayscale(1);
	transform: rotate(-35deg);
}

.messageInput {
	overflow-y: auto;
	max-height: 200px;
}

.showLottie {
	display: block !important;
	height: 100px;
	opacity: 1 !important;
	visibility: visible !important;
}

@media (max-width: 768px) {
	.stepHeading {
		font-size: 18px !important;
	}

	.categoriesContainer {
		justify-content: center;

		.category {
			margin: 20px;

			.categoryIcon {
				width: 56px;
				height: 56px;
			}
		}
	}

	.envelopeLottieContainer {
		right: -100px;
		bottom: -100px;
		width: 300px;
		height: 300px;
	}

	.successContainer {
		height: 120px;
	}

	.contactFormContainer {
		min-height: 680px;
	}

	.navigationButtons {
		bottom: 120px;
		gap: 0;
	}

	.nextBtn,
	.prevBtn,
	.sendBtn {
		margin: 5px 20px;
	}

	.input,
	.input:active,
	.input:focus {
		width: 70% !important;
	}
}

@media (max-width: 556px) {
	.stepsContainer {
		top: -50px;
	}
}

@media (max-width: 520px) {
	.navigationButtons {
		flex-direction: column-reverse;
	}
}

@media (max-width: 480px) {
	.successContainer {
		height: 100px;
	}

	.input,
	.input:active,
	.input:focus {
		width: 90% !important;
	}

	.step:nth-of-type(2),
	.step:nth-of-type(3),
	.step:nth-of-type(4) {
		margin-bottom: 40px;
	}
}
