@import '/src/utils/sass/mixins.scss';

.mainContainer {
	background: $tertiary-background;
	color: #fff;
}

.textSection {
	max-width: 80%;
	margin: auto;
}

.securityGridContainer,
.mobileDrivenDesignGridContainer {
	gap: 0;
	padding: 0 !important;
}

.brandPresenceGridContainer,
.noCodeGridContainer,
.userEngagementGridContainer,
.stripeGridContainer {
	gap: 2rem;
	padding: 6rem;
}

.securityGridContainer,
.brandPresenceGridContainer,
.userEngagementGridContainer {
	background-color: white;
	color: black;
}

.stripeLottieColumn {
	@include flexCenter();

	position: relative;
	border-radius: 10px;
	background-color: white;

	.stripe {
		z-index: 2;
		padding: 15%;
	}

	.stripeIntegrationLottie {
		position: absolute;
		top: 58%;
		left: 74%;
		width: 20%;
	}
}

.shootingStarsLottieColumn {
	@include flexCenter();

	position: relative;
	overflow: hidden;
	border-radius: 20px;

	.shootingStarsLottie {
		position: absolute;
	}

	.userEngagementSection {
		z-index: 1;
		padding: 15%;
	}
}

.balancingSecurityLottie {
	position: absolute;
	width: 50%;
}

.lottieColumn {
	@include flexCenter();

	position: relative;
}

.cmsLottie {
	position: relative;
	z-index: 1;
	width: 60%;
}

.cmsBackgroundLottie {
	position: absolute;
	overflow: hidden;
	border-radius: 25px;
}

.collageContainer {
	display: grid;
	grid-template-areas:
		'b1 b1 b1 b2 b2 b2 b5 b5 b5'
		'b1 b1 b1 b3 b3 b4 b5 b5 b5';
	grid-template-rows: repeat(2, 0fr);
	gap: 1rem;
	padding: 3%;
	background-color: $secondary-background;
	background-image: url('../../../images/stars.png');
	background-size: cover;

	.collageBoxOne,
	.collageBoxTwo,
	.collageBoxThree,
	.collageBoxFour,
	.collageBoxFive {
		& img {
			object-fit: fill;
			width: 100%;
			height: stretch;
		}
	}

	.collageBoxThree,
	.collageBoxFour {
		& img {
			border-radius: 10px;
		}
	}

	.collageBoxOne {
		grid-area: b1;
	}

	.collageBoxFive {
		grid-area: b5;
	}

	.collageBoxTwo {
		grid-area: b2;
	}

	.collageBoxThree {
		grid-area: b3;
		padding: 5%;
		border-radius: 10px;
		background-color: #eedeab;
	}

	.collageBoxFour {
		@keyframes moveBackground {
			0% {
				object-position: 0 0;
			}

			100% {
				object-position: 0 100%;
			}
		}

		grid-area: b4;
		overflow: hidden;
		padding: 3%;
		border-radius: 10px;
		background-color: #95b8d1;

		& img {
			object-fit: cover;
			animation: moveBackground 30s ease-in-out infinite;
		}
	}
}

@include responsive(largeTablet) {
	.mediaContainer {
		padding: 1rem;
	}

	.textSection {
		max-width: 100%;
		padding: 4rem 1rem;
	}

	.brandPresenceGridContainer,
	.noCodeGridContainer,
	.userEngagementGridContainer,
	.stripeGridContainer {
		gap: 0;
	}

	.securityGridContainer,
	.mobileDrivenDesignGridContainer {
		grid-template-columns: 1fr !important;
	}
}

@include responsive(smallTablet) {
	.brandPresenceGridContainer,
	.noCodeGridContainer,
	.userEngagementGridContainer,
	.stripeGridContainer {
		padding: 0;
	}
}

@include responsive(smallDevice) {
	.mainContainer p {
		font-size: 14px !important;
	}

	.textSection {
		padding: 2rem 1rem;

		h4 {
			font-size: 18px !important;
		}
	}

	.collageContainer {
		grid-template-areas:
			'b1 b1 b1'
			'b2 b2 b2'
			'b3 b3 b4'
			'b5 b5 b5';
		grid-template-rows: 0fr 0fr minmax(12%, 0fr) 0fr;
		padding: 1rem;
	}
}
