@import '/src/utils/sass/mixins.scss';

.mainContainer {
	background: $secondary-background;
	color: #fff;
}

.contentContainer {
	width: 80%;
	height: 100%;
	margin: 0 auto;
}

.blueContainer,
.whiteContainer {
	padding: 40px 20px;
}

.blueContainer {
	background-color: $tertiary-background;
}

.whiteContainer {
	background-color: #fff;
	color: #000;
}

.dnaLottieContainer {
	overflow: hidden;
	background-color: #f9f9f9;

	.dnaLottie {
		height: 500px;
		transform: rotate(-20deg) scale(1.4);
	}
}

.toughChallenges {
	padding: 60px 20px;
	font-weight: bold;
	text-align: center;
}

@media (max-width: 1200px) {
	.dnaLottieContainer .dnaLottie {
		height: 400px;
	}
}

@media (max-width: 992px) {
	.whiteContainer,
	.blueContainer {
		padding: 60px 20px;
	}

	.dnaLottieContainer .dnaLottie {
		height: 500px;
	}
}

@media (max-width: 768px) {
	.contentContainer {
		width: 90%;
	}

	.dnaLottieContainer .dnaLottie {
		height: 400px;
	}
}

@media (max-width: 576px) {
	.mainContainer p {
		font-size: 14px !important;
	}
}
