@import '/src/utils/sass/mixins.scss';

.colorPaletteContainer {
	@include flexCenter();

	overflow: hidden;
	width: 100%;
	height: 60vh;
	min-height: 55vh;

	.tabsContainer {
		display: flex;
		justify-content: center;
		border-radius: 25px;

		.tabLayout {
			display: flex;
			justify-content: center;
		}

		.fontsTab,
		.gradientsTab,
		.colorPaletteTab {
			position: absolute;
			bottom: 0;
			flex-direction: column;
			justify-content: left;
			padding: 10px;
			border-radius: 10px 10px 0 0;
			background-color: #fff;
			color: #000;
			filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.22));
		}

		.fontsTab {
			width: 65%;
			height: 55%;
		}

		.gradientsTab {
			width: 75%;
			height: 45%;

			.gradientColors {
				width: 90%;
				height: 70px;
				margin-left: 5px;
				border-radius: 10px;
			}
		}

		.colorPaletteTab {
			overflow: hidden;
			width: 85%;
			height: 30%;

			.paletteColors {
				width: 20%;
				height: 60px;
				margin-top: 15px;
				margin-left: 5px;
				border-radius: 10px;
			}
		}
	}

	img {
		position: absolute;
		margin-top: 50px;
	}
}

@include responsive(laptop) {
	.colorPaletteContainer {
		height: 45vh;

		.tabsContainer {
			.fontsTab {
				height: 65%;
			}

			.gradientsTab {
				height: 50%;

				.gradientColors {
					height: 50px;
				}
			}

			.colorPaletteTab {
				height: 30%;

				.paletteColors {
					height: 45px;
				}
			}
		}
	}
}

@include responsive(largeTablet) {
	.colorPaletteContainer {
		img {
			width: 15%;
			margin-top: 20px;
		}
	}
}

@include responsive(smallDevice) {
	.colorPaletteContainer {
		img {
			width: 18%;
		}
	}
}

@include responsive(extraSmallDevice) {
	.tabsContainer {
		margin-bottom: -2px;

		.fontsTab {
			width: 55%;
		}

		.gradientsTab {
			width: 65%;
		}

		.colorPaletteTab {
			width: 70%;

			.paletteColors {
				height: 10px;
			}
		}
	}
}

@media (max-width: 396px) {
	.colorPaletteContainer {
		.tabsContainer {
			.colorPaletteTab {
				.paletteColors {
					height: 30px;
				}
			}
		}
	}
}
