.cardContainer {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	width: 300px;
	height: 100px;
	margin: 10px;
	padding: 0 15px;
	border-radius: 10px;
	background-color: #fff;
	color: #000;
	-webkit-box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	-moz-box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	text-align: center;
	transition: background 0.3s;
}

.cardContainer p {
	margin-bottom: 0;
}

.cardContainer div {
	margin-left: 15px;
	text-align: left;
}

.title {
	color: #555;
	font-weight: bold;
	font-size: 18px;
}

.category {
	color: #777;
	font-size: 14px;
}

.tag {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: #d59607;
}

.designTag {
	background: #811180;
}

.developmentTag {
	background: #116481;
}
