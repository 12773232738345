.conveyorBeltLine {
	pointer-events: none;
}

.mainContainer {
	max-width: 100%;
}

.show {
	opacity: 1;
	transition: all 0.3s;
}

.hide {
	opacity: 0;
	transition: all 0.3s;
}

.inactive {
	font-weight: normal;
	opacity: 0.6;
	transition: all 0.3s;
}

.active {
	font-weight: bold;
	opacity: 1;
	transition: all 0.3s;
}
