@import '/src/utils/sass/variables';

.navbar {
	position: absolute !important;
	z-index: 999999;
	width: 100%;
	padding: 10px 0;
	background-color: rgba(0, 0, 0, 0);
}

.navLink {
	margin: 0 20px;
	border-bottom: 2px solid rgba(255, 255, 255, 0);
	color: #fff !important;
	cursor: none !important;
	transition: all 0.3s;
}

.navLink:hover,
.activeLink {
	border-bottom: 2px solid rgba(255, 255, 255, 1);
}

.navbarBrand {
	cursor: none;

	.logo {
		z-index: 999;
		width: 250px;
		margin-left: 20px;
	}
}

.navbarToggleIcon {
	position: absolute;
	top: 15px;
	right: 25px;
	width: 25px;
	height: 20px;
}

.navbarToggle,
.closeNavbarToggle {
	margin-right: 15px;
	border: none !important;
	background: none !important;
	color: #fff;
	box-shadow: none !important;
	outline: none !important;
	cursor: none !important;

	& img {
		height: 20px !important;
	}
}

.navbarToggle {
	z-index: 5;
}

.closeNavbarToggle {
	z-index: 10;
	margin-top: 10px;
}

.navbarCollapse {
	position: relative;
}

@media (max-width: 767px) {
	.navbarCollapse {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
		display: block !important;
		overflow-x: hidden;
		width: 100vw !important;
		height: 100vh !important;
		margin-right: 0;
		background: $primary-background !important;
		text-align: center;
		transition: left 0.3s !important;
	}

	.navbarToggle,
	.closeNavbarToggle {
		margin-right: 10px;
	}

	.navbarToggle {
		display: flex !important;
	}

	.showNavbar {
		left: 0;

		.closeNavbarToggle {
			position: absolute;
			top: 5px;
			right: 10px;
			z-index: 99;
			font-size: 25px;
		}

		.closeNavbarToggle,
		.closeNavbarToggle img {
			display: flex !important;
		}
	}

	.collapseNavbar {
		left: 100vw;
	}

	.navLinks {
		margin-top: 50px;
	}

	.navLink {
		margin: 0;
		padding: 20px !important;
	}

	.navLink:hover,
	.activeLink {
		border-bottom: 2px solid rgba(255, 255, 255, 0);
		background: rgba(255, 255, 255, 0.1) !important;
	}

	.logo {
		width: 200px;
	}
}
