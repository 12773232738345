@import '/src/utils/sass/mixins';

.stellarPromiseSection {
	@include flexCenter(column);

	position: relative;
	height: fit-content;
	padding: 120px 20px;
	background-color: #efefef;
	color: $primary-text;
}

.textContainer {
	min-height: 250px;
	line-height: 30px;
	text-align: left;
	opacity: 0;
}

.sectionHeading {
	margin-bottom: 60px;
	font-family: $primaryFont !important;
	opacity: 0;
	transition: opacity 0.5s ease;
}

.heroQuote {
	font-family: $primaryFont !important;
	text-align: left;
	opacity: 0;
}

.star {
	position: absolute;
	bottom: -50px;
	left: -50px;
	z-index: 1;
	pointer-events: none;
}

@media (max-width: 768px) {
	.heroQuote {
		max-width: 90% !important;
		margin-bottom: 60px;
		text-align: center;
	}

	.sectionTextContainer {
		justify-content: center;
	}

	.textContainer {
		max-width: 90% !important;
		text-align: center;
	}
}
