@import '/src/utils/sass/mixins';

.bulletsContainer {
	@include flexCenter(column);

	position: fixed;
	top: 0;
	right: 30px;
	bottom: 0;
	z-index: 1000;
	height: fit-content;
	margin: auto;
	padding: 15px 10px;
	border-radius: 20px;
	background-color: rgba(255, 255, 255, 0.05);
}

.sectionBullet,
.selectedBullet {
	margin: 10px 0;
	border-radius: 50%;
	background-color: rgba(14, 15, 34, 0.5);
	-webkit-box-shadow: 0 0 3px 0 #fff;
	-moz-box-shadow: 0 0 3px 0 #fff;
	box-shadow: 0 0 3px 0 #fff;
	transition: all 0.3s;
}

.sectionBullet {
	width: 10px;
	height: 10px;
}

.selectedBullet,
.sectionBullet:hover {
	width: 15px !important;
	height: 15px !important;
}

@media (max-width: 576px) {
	.bulletsContainer {
		right: 10px !important;
	}

	.sectionBullet:hover {
		width: 10px !important;
		height: 10px !important;
	}
}
