@import '/src/utils/sass/mixins.scss';

.mainContainer {
	background: $tertiary-background;
	color: #fff;
}

.textSection {
	max-width: 80%;
	margin: auto;
}

.recruitmentGridContainer,
.broaderAppealGridContainer {
	gap: 0;
	padding: 0;
}

.webDevelopmentGridContainer,
.carouselGridContainer {
	gap: 0;
	padding: 6rem;
}

.testTubesGridContainer {
	gap: 2rem;
	padding: 6rem;
}

.broaderAppealGridContainer,
.carouselGridContainer {
	background-color: #fff;
	color: black;
}

.recruitmentLottieColumn {
	@include flexCenter();

	position: relative;

	.recruitmentLottie {
		position: absolute;
		width: 70%;
	}

	.recruitmentBackground {
		position: relative;
		z-index: 0;
		width: 100%;
	}
}

.testTubesLottieColumn {
	@include flexCenter();

	position: relative;
	overflow: hidden;
	margin: auto;
	padding: 6rem;
	border-radius: 15px;
	background-color: #fff;

	.testTubesLottie {
		position: absolute;
		z-index: 2;
		width: 50%;
	}

	.testTubesOvalBackground {
		z-index: 1;
	}

	.floatingBubblesLottie {
		position: absolute;
		z-index: 0;
	}
}

.carouselContainer {
	max-width: 90%;
	margin: auto;

	.carouselPreviousControl,
	.carouselNextControl {
		cursor: none !important;
	}

	.carouselItemContainer {
		position: relative;
		padding: 30px 0;
		border: 1px solid #cececf;
		border-radius: 15px;
		background-color: #f5f5f5;
	}

	.carouselItemTitle {
		position: relative;
		display: flex;
		justify-content: center;
		padding-bottom: 15px;
		color: #424141;
		font-size: 22px;
		letter-spacing: 8px;
		text-transform: uppercase;
	}

	.firstCarouselAnimation,
	.secondCarouselAnimation,
	.thirdCarouselAnimation {
		display: flex;
		width: 200px;
		height: 200px;
		margin: auto auto 30px;
	}

	.secondCarouselAnimation {
		width: 300px;
	}

	.thirdCarouselAnimation {
		width: 280px;
	}

	.carousel {
		& button {
			width: 8px !important;
			height: 8px !important;
			border: 1px !important;
			border-radius: 25px !important;
			background-color: #26335f !important;
			cursor: none !important;
		}
	}
}

@include responsive(largeTablet) {
	.textSection {
		max-width: 100%;
		padding: 4rem 1rem;
	}

	.testTubesGridContainer,
	.webDevelopmentGridContainer,
	.carouselGridContainer {
		gap: 0;
		padding: 3rem 0;
	}

	.recruitmentGridContainer,
	.broaderAppealGridContainer,
	.testTubesGridContainer,
	.webDevelopmentGridContainer,
	.carouselGridContainer {
		grid-template-columns: 1fr !important;
	}

	.carouselContainer {
		max-width: 70%;
	}

	.collage {
		content: url('../../../images/telis/collageMobile.png');
	}
}

@include responsive(smallDevice) {
	.mainContainer p {
		font-size: 14px !important;
	}

	.carouselContainer {
		max-width: 90%;
	}

	.textSection {
		padding: 2rem 1rem;
	}
}

@include responsive(extraSmallDevice) {
	.carouselContainer {
		.carouselItemTitle {
			font-size: 16px;
		}

		.firstCarouselAnimation,
		.secondCarouselAnimation,
		.thirdCarouselAnimation {
			width: 150px;
			height: 150px;
		}

		.secondCarouselAnimation {
			width: 220px;
		}

		.thirdCarouselAnimation {
			width: 200px;
		}

		.carouselPreviousControl,
		.carouselNextControl {
			& span {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
}
