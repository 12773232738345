@import '/src/utils/sass/mixins.scss';

.mainContainer {
	background: $tertiary-background;
	color: #fff;
}

.textSection {
	max-width: 80%;
	margin: auto;
}

.rightToneTextSection {
	margin: 0 auto;
	padding: 1rem 4rem;
}

.cuttingEdgeGridContainer,
.neurosurgeryGridContainer {
	gap: 0;
	padding: 0 !important;
}

.zoneOfMagicGridContainer,
.rightToneSectionContainer,
.wowMomentGridContainer,
.scalabilityGridContainer {
	padding: 6rem;
}

.scalabilityGridContainer {
	gap: 2rem;
}

.cuttingEdgeGridContainer,
.zoneOfMagicGridContainer,
.wowMomentGridContainer {
	background-color: white;
	color: black;
}

.brainLottieContainer {
	@include flexCenter();

	position: relative;
	z-index: 1;
	overflow: hidden;
	height: 550px;
	background-image: url('../../../images/zeta/background.png');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

	.starsLottie {
		position: absolute;
		top: 5px;
	}

	.brain {
		position: absolute;
		animation: moveUp 2s 200ms infinite, moveDown 2s 2400ms infinite alternate;
		animation-timing-function: ease-in-out;
		mix-blend-mode: lighten;
	}

	.tube {
		position: absolute;
		top: -50px;
	}

	.platform {
		position: absolute;
		bottom: 0;
	}
}

.mediaContainer {
	border-radius: 20px;
}

@keyframes moveUp {
	from {
		transform: translateY(0);
	}

	to {
		transform: translateY(-40px);
	}
}

@keyframes moveDown {
	from {
		transform: translateY(0);
	}

	to {
		transform: translateY(40px);
	}
}

@include responsive(laptop) {
	.brainLottieContainer {
		.tube {
			top: -30px;
		}
	}
}

@include responsive(largeTablet) {
	.mediaContainer {
		padding: 1rem;
		border-radius: 30px;
	}

	.textSection {
		max-width: 100%;
		padding: 4rem 1rem;
	}

	.rightToneTextSection {
		padding: 4rem 0;
	}

	.zoneOfMagicGridContainer,
	.wowMomentGridContainer,
	.scalabilityGridContainer {
		gap: 0;
	}

	.cuttingEdgeGridContainer,
	.scalabilityGridContainer {
		grid-template-columns: 1fr !important;
	}
}

@include responsive(smallTablet) {
	.zoneOfMagicGridContainer,
	.wowMomentGridContainer,
	.scalabilityGridContainer {
		padding: 0;
	}

	.rightToneSectionContainer {
		padding: 1rem;
	}
}

@include responsive(smallDevice) {
	.mainContainer p {
		font-size: 14px !important;
	}

	.rightToneTextSection {
		padding: 3rem 0;
	}

	.textSection {
		padding: 2rem 1rem;

		h4 {
			font-size: 18px !important;
		}
	}
}
