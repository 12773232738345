@import '/src/utils/sass/mixins';

.contactUsSection {
	@include flexCenter(column);

	position: relative;
	z-index: 0;
	min-height: 200px;
	padding: 50px 0 100px;
	background-color: #eee;
	color: $primary-text;
	font-family: $primaryFont !important;
}

.mainContent {
	text-align: center;
}

.contactUsBtn {
	@include primaryButton();

	opacity: 0;
}

.heading {
	margin-bottom: 50px;
	opacity: 0;
}
