@import './variables.scss';

@mixin flexCenter($direction: row) {
	display: flex;
	flex-direction: $direction;
	align-items: center;
	justify-content: center;
}

@mixin primaryButton() {
	padding: 10px 100px;
	border: none !important;
	border-radius: 50px;
	background: $secondary-background;
	color: #fff !important;
	box-shadow: none !important;
	outline: none !important;
	font-size: 20px;
	font-family: 'Open Sans', sans-serif !important;
	text-decoration: none !important;
	cursor: none !important;
	transition: background-color 0.2s ease-in;

	&:hover {
		background-color: $tertiary-background !important;
	}
}

@mixin secondaryButton() {
	padding: 10px 100px;
	border: 1px solid $secondary-background !important;
	border-radius: 50px;
	background-color: #fff !important;
	color: $secondary-background !important;
	box-shadow: none !important;
	outline: none !important;
	font-family: 'Open Sans', sans-serif !important;
	text-decoration: none !important;
	cursor: none !important;
	transition: background-color 0.2s ease-in;

	&:hover {
		background-color: #d7e0ff !important;
	}
}

/* Breakpoint mixin for all devices */
@mixin responsive($breakpoint) {
	@if $breakpoint == extraSmallDevice {
		@media (max-width: 480px) {
			@content;
		}
	}

	@else if $breakpoint == smallDevice {
		@media (max-width: 576px) {
			@content;
		}
	}

	@else if $breakpoint == smallTablet {
		@media (max-width: 768px) {
			@content;
		}
	}

	@else if $breakpoint == largeTablet {
		@media (max-width: 992px) {
			@content;
		}
	}

	@else if $breakpoint == laptop {
		@media (max-width: 1200px) {
			@content;
		}
	}

	@else if $breakpoint == desktop {
		@media (max-width: 1440px) {
			@content;
		}
	}

	@else if $breakpoint == bigScreen {
		@media (min-width: 1441px) {
			@content;
		}
	}

	@else {
		@content;
	}
}
