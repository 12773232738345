@import '/src/utils/sass/mixins';

.mainContainer {
	position: relative;
	width: 100%;
	min-height: 100vh;
	background: linear-gradient($primary-background, $secondary-background);
}

.textContainer {
	@include flexCenter(column);

	top: calc(50% - 20vh) !important;
	width: 100%;
	min-height: 100vh;
	margin-top: 50px;
	color: #fff;
	text-align: center;
	pointer-events: all;
}

.infoTextContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: 50px;
	text-align: center;
}

.infoText {
	width: 50%;
	text-align: center;
	opacity: 0;
}

.logoContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	max-width: 1000px;
	margin: 0 auto;
}

.logo {
	width: 100%;
	height: 15vw;
	margin-bottom: 25px;
}

.hoverText,
.tapText {
	color: rgba(255, 255, 255, 0.6);
	font-size: 14px;
	opacity: 0;
}

.hoverText {
	margin-bottom: -20px;
}

.tapText {
	visibility: hidden;
}

@media (max-width: 768px) {
	.textContainer {
		top: calc(50% - 15vh) !important;
	}
}

@media (max-width: 576px) {
	.textContainer {
		top: calc(50% - 15vh) !important;
	}

	.tapText {
		visibility: visible;
	}

	.hoverText {
		visibility: hidden;
	}

	.infoTextContainer {
		width: 100vw;
	}

	.infoText {
		width: 100%;
		margin: 0 7vw;
		font-size: 14px;
	}
}
