.mainContainer {
	display: flex;
	justify-content: center;
	background-color: #92a0cf;
}

.contentContainer {
	position: relative;
	overflow: hidden;
	width: 100%;
	max-width: 1900px;
}

.app {
	overflow: hidden !important;
}
