@import '/src/utils/sass/mixins.scss';

.gridContainer {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	gap: 4rem;
	padding: 6rem 0;

	.column {
		display: grid;
		align-items: center;
		width: 100%;
		height: 100%;
	}
}

@include responsive(largeTablet) {
	.gridContainer {
		grid-template-columns: 1fr;

		.reverse {
			order: 1;
		}
	}
}
