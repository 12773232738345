@import '/src/utils/sass/mixins.scss';

.mainContainer {
	background: $tertiary-background;
	color: #fff;
}

.commonInterestsGridContainer,
.brandingGridContainer {
	gap: 0;
	padding: 0;
}

.textSection {
	max-width: 80%;
	margin: auto;
}

.commonInterestsGridContainer,
.colorPsyhcologyGridContainer,
.ethicalUxGridContainer {
	background: #fff;
	color: #000;
}

.colorPsyhcologyGridContainer,
.ethicalUxGridContainer,
.monorepoGridContainer,
.illustrationGridContainer {
	gap: 1.5rem;
	padding: 6rem;
}

.illustrationLottieColumn {
	padding: 2rem 4rem;
	border-radius: 15px;
	background-color: #fff;
}

.ethicalUxLottieColumn {
	@include flexCenter();

	position: relative;
	overflow: hidden;
	width: 100%;
	margin: auto;
	border-radius: 15px;
	background-color: #edf1fe;

	.ethicalUxLottie {
		position: absolute;
		z-index: 2;
	}

	.ethicalUxBackground {
		z-index: 1;
	}
}

.collageContainer {
	padding: 2rem;
	background-color: $secondary-background;
	background-image: url('../../../images/stars.png');
	background-size: cover;
}

@include responsive(largeTablet) {
	.textSection {
		max-width: 100%;
		padding: 4rem 1rem;
	}

	.colorPsyhcologyGridContainer,
	.ethicalUxGridContainer,
	.monorepoGridContainer,
	.illustrationGridContainer {
		padding: 2rem;
	}

	.collageContainer {
		padding: 0;
	}

	.collage {
		content: url('../../../images/groupLoop/collageMobile.webp');
	}
}

@include responsive(smallDevice) {
	.mainContainer p {
		font-size: 14px !important;
	}

	.textSection {
		padding: 2rem 1rem;

		h4 {
			font-size: 18px !important;
		}
	}

	.commonInterestsGridContainer,
	.brandingGridContainer {
		.textSection {
			padding: 2rem 1rem;
		}
	}

	.colorPsyhcologyGridContainer,
	.ethicalUxGridContainer,
	.monorepoGridContainer,
	.illustrationGridContainer {
		padding: 2rem 1rem;

		.textSection {
			padding: 0.5rem 0;
		}
	}
}
