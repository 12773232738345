@import '/src/utils/sass/mixins.scss';

.mainContainer {
	background: $tertiary-background;
	color: #fff;
}

.textSection {
	max-width: 80%;
	margin: auto;
}

.navigateContentGridContainer,
.contentExplorationGridContainer {
	gap: 0;
	padding: 0;
}

.navigateContentGridContainer,
.communityGridContainer,
.contentGridContainer,
.triviaGridContainer,
.multiplePlatformsGridContainer {
	background: #fff;
	color: #000;
}

.navigateContentLottieColumn {
	@include flexCenter(row);

	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin: auto;
	background-color: #342046;

	.navigateContentLottie {
		position: absolute;
		z-index: 3;
		width: 65%;
		transform: translate(5%, -5%);
	}

	.contentBackgroundLottie {
		position: absolute;
		z-index: 2;
		width: 80%;
		transform: translateY(18%);
	}

	.navigateContentBackground {
		z-index: 1;
		height: 100%;
	}
}

.contentExplorationGridContainer {
	color: #fff;
}

.paddedContainer {
	gap: 1.5rem;
	padding: 6rem;
}

.calendarLottieColumn {
	@include flexCenter(row);

	padding: 2rem 4rem;
	border-radius: 15px;
	background-color: #fff;

	.calendarLottie {
		width: 80%;
	}
}

.multiplePlatformsLottieColumn {
	@include flexCenter();

	position: relative;
	overflow: hidden;
	width: 100%;
	margin: auto;
	border-radius: 15px;

	.multiplePlatformsLottie {
		position: absolute;
		z-index: 2;
		width: 95%;
	}

	.multiplePlatformsBackground {
		z-index: 1;
	}
}

.collageContainer {
	height: 100vh;
	background-color: $secondary-background;
	background-image: url('../../../images/stars.png');
	background-size: cover;
	text-align: center;

	& img {
		object-fit: contain;
		height: 100vh;
	}
}

@include responsive(laptop) {
	.contentGridContainer,
	.darkModeGridContainer,
	.triviaGridContainer,
	.adminConsoleGridContainer {
		img {
			object-fit: contain;
		}
	}
}

@include responsive(largeTablet) {
	.paddedContainer {
		padding: 2rem;
	}

	.textSection {
		max-width: 100%;
		padding: 2.5rem 1rem;
	}

	.collageContainer {
		padding: 0;
	}

	.navigateContentLottie {
		transform: translateY(-5%) !important;
	}
}

@include responsive(smallDevice) {
	.mainContainer p {
		font-size: 14px !important;
	}

	.textSection {
		padding: 2rem 1rem;

		h4 {
			font-size: 18px !important;
		}
	}

	.navigateContentGridContainer,
	.contentExplorationGridContainer {
		.textSection {
			padding: 2rem 1rem;
		}
	}

	.paddedContainer {
		padding: 2rem 1rem;

		.textSection {
			padding: 0.5rem 0;
		}
	}
}
