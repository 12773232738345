@import '/src/utils/sass/mixins';

.comingSoonContainer {
	position: relative;
	overflow: hidden;
	width: 100vw;
	min-height: 700px;
	background: linear-gradient($primary-background, $secondary-background);
}

.contentContainer {
	@include flexCenter(Column);

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	margin: auto;
	text-align: center;
}

.comingSoonText {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: 50px;
	color: #fff;
	font-weight: 400;
	font-size: 60px !important;
	font-family: $primaryFont;
	transform: translate(-50%, -50%);
}

.scene,
.moon,
.moonShadow,
.eye {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	margin: auto;
	transform: translateY(-50%);
}

.eye {
	bottom: 34px;
	width: 6px !important;
	height: 6px;
	border-radius: 10px;
	background: #53487a;
	animation: blink 1s 0s infinite linear;
}

.leftEye {
	bottom: -120px;
	left: -34px;
}

.rightEye {
	bottom: -120px;
	left: 34px;
}

.scene {
	width: 500px;
	height: 500px;
	perspective: 2600px;
}

.moonShadow {
	bottom: 400px;
	width: 84px;
	height: 21px;
	border-radius: 100%;
	box-shadow: 0 200px 20px rgba(0, 0, 0, 0.3);
	animation: moon 6s infinite ease;
	transform-style: preserve-3d;
}

.moonContainer {
	animation: moonWrap 6s infinite ease;
}

.moon {
	top: -220px;
	width: 84px;
	height: 84px;
	border-radius: 42px;
	background: #a5c6ff;
	box-shadow: 0 0 0 4px rgba(169, 187, 236, 0.388) inset, -30px -20px 50px #637bff inset;
	animation: moon 6s infinite ease;
	transform-style: preserve-3d;
}

.eyes {
	animation: moon_eye 6s infinite ease;
}

@keyframes blink {
	40%,
	60% {
		height: 6px;
	}

	50% {
		height: 0;
	}
}

@keyframes moonWrap {
	0%,
	33%,
	43%,
	73%,
	83%,
	100% {
		transform: translateY(370px);
	}

	38%,
	78% {
		transform: translateY(400px);
	}
}

@keyframes moon {
	0%,
	33%,
	83%,
	100% {
		left: -400px;
	}

	43%,
	73% {
		left: 400px;
	}
}

@keyframes moon_eye {
	0%,
	33%,
	83%,
	100% {
		transform: rotate(-10deg);
	}

	43%,
	73% {
		transform: rotate(10deg);
	}
}

@media (max-width: 550px) {
	@keyframes moon {
		0%,
		33%,
		83%,
		100% {
			left: -60vw;
		}

		43%,
		73% {
			left: 60vw;
		}
	}

	@keyframes moonWrap {
		0%,
		33%,
		43%,
		73%,
		83%,
		100% {
			transform: translateY(220px);
		}

		38%,
		78% {
			transform: translateY(250px);
		}
	}

	.moon {
		top: -20px;
	}

	.comingSoonText {
		width: 100%;
		margin-top: 120px;
		font-size: 40px !important;
		text-align: center;
	}

	.moonContainer {
		width: 100vw;
	}
}
