@import '/src/utils/sass/mixins';

.projectsSection {
	@include flexCenter(column);

	position: relative;
	min-height: 800px;
	padding: 150px 0;
	background-color: #fff;
	color: #5a5b5c;
}

.projectsHeading {
	margin-bottom: 30px;
	font-family: $primaryFont !important;
}

.projectsContainer {
	position: relative;
	width: 100%;
	padding: 0 20px;
}

.projectsCards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.seeMoreCardContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	color: #5a5b5c;
	text-decoration: none;
	cursor: none !important;
}

.seeMoreCard {
	@include flexCenter(column);

	position: relative;
	z-index: 1;
	width: 100%;
	height: 450px;
	margin: 10px;
	padding: 15px;
	border-radius: 10px;
	background: #fff;
	color: #5a5b5c !important;
	-webkit-box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	-moz-box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	font-size: 18px;
	text-align: center;
	text-decoration: none !important;
	cursor: none !important;
	transition: background 0.3s;
}

.backgroundLogoContainer {
	position: absolute;
	bottom: -20vh;
	left: 0;
	z-index: 0;
	display: flex;
	align-items: center;
	height: 80vh;
	pointer-events: none;
}

.projectLogoBackground {
	margin-bottom: -10%;
	margin-left: -20%;
	opacity: 0.08;
}
