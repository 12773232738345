@import '/src/utils/sass/mixins';

.projectsSection {
	position: relative;
	min-height: 920px;
	background-color: #fff;
	color: #5a5b5c;
}

.headingContainer {
	width: 100%;
	padding-top: 70px;
	background: $secondary-background;
}

.dropdownScreenOverlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
}

.projectsHeading {
	position: absolute;
	flex: 1;
	width: 100%;
	height: 100%;

	.title {
		@include flexCenter();

		height: 100%;
		color: #fff;
		font-family: $primaryFont;
	}
}

.projectsContainer {
	position: relative;
	width: 100%;
	padding: 60px 0;
}

.projectsCards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 0fr));
	justify-content: center;
}

.filterSection {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 0 30px !important;

	.tagsSection {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;
		align-items: flex-start;
		min-height: 100px;
		margin-bottom: 15px;
		padding-right: 15px;
		text-align: left;
	}

	.selectedTags {
		padding: 7px 25px;
		border-color: transparent;
		border-radius: 30px;
		background-color: #ededed;
		color: black;
		box-shadow: none;
		white-space: nowrap;
		cursor: none;
		transition: all 0.3s;

		&:hover {
			border-color: transparent;
			background-color: #d7e0ff;
			color: black;
		}

		&:active {
			border-color: transparent !important;
			background-color: #d7e0ff !important;
			color: black !important;
		}

		&:focus {
			border-color: transparent;
			background-color: #d7e0ff;
		}

		&:active:focus {
			box-shadow: none !important;
		}
	}

	.tagColor {
		margin-right: 6px;
		font-weight: 900;
	}

	.filterDropdownButton {
		position: relative;
		z-index: 1000;
		padding: 7px 40px;
		border: none;
		border-radius: 30px;
		background: $tertiary-background;
		color: white;
		white-space: nowrap;
		cursor: none;
		transition: all 0.3s;

		.chevronDown {
			transition: 0.3s ease;
			transform: rotate(360deg);
		}

		.chevronUp {
			transition: 0.3s ease;
			transform: rotate(180deg);
		}

		&:focus {
			background-color: #17193a;
			box-shadow: none;
		}

		&:hover {
			background-color: $secondary-background;
		}

		&:active {
			background-color: #17193a !important;
			box-shadow: none !important;
		}

		&:checked + .filterDropdownButton {
			background-color: #17193a !important;
			box-shadow: none !important;
		}
	}

	.filterDropdownButton::after {
		display: none;
	}

	.dropdownMenu {
		position: relative;
		grid-template-columns: 1fr 1fr;
		min-width: 15rem;
		padding: 5px 25px;
		border-radius: 10px;
		cursor: none;

		& label {
			font-weight: 500;
			cursor: none;
		}

		& li {
			border-radius: 5px;
			transition: all 0.3s;

			&:hover {
				background-color: #d7e0ff;
			}

			&:active,
			&:focus {
				background-color: #d7e0ff;
				color: $primary-text;
			}
		}

		.dropdownMenuHeading {
			grid-column: 1/3;
			margin: 10px 0 0;
			padding: 5px 10px;
			color: #56595e;
		}

		.dropdownMenuDivider {
			position: absolute;
			bottom: 45px;
			width: 100%;
			color: #d8d8d8;
		}
	}

	.dropdownClearAllFilters {
		grid-column: 1/3;
		width: fit-content;
		padding: 30px 10px 0;
		color: #191c40;
		font-weight: 600;

		&:hover {
			text-decoration: underline;
		}
	}

	.tagsClearAllFilters {
		width: fit-content;
		padding: 7px 25px;
		color: #191c40;
		font-weight: 600;

		&:hover {
			text-decoration: underline;
		}
	}

	.checkboxSection {
		display: flex;
		align-items: center;
		height: 30px;

		.checkbox {
			margin-top: 1px;
			border-color: black;
			border-radius: 1px;
			box-shadow: none;
			font-size: 20px;
			cursor: none;
			transition: all 0.3s;

			&:checked[type='checkbox'] {
				background-color: black;
				box-shadow: none;
			}

			&:focus {
				border-color: black;
				box-shadow: none;
			}
		}
	}
}

.dropdownMenuGrid {
	display: grid !important;
}

.comingSoonCardContainer {
	display: flex;

	&.halfCard {
		& p {
			margin-top: -15%;
		}
	}

	&.fullCard {
		& p {
			margin-top: 0;
		}
	}
}

.comingSoonCard {
	@include flexCenter(column);

	width: 100%;
	margin: 10px;
	padding: 15px;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 2px 2px 12px -7px rgba(0, 0, 0, 0.41);
	text-align: center;
	transition: background 0.3s;

	&.halfCard {
		height: 220px;
	}

	&.fullCard {
		height: 450px;
	}
}

.comingSoonLogo {
	width: 40%;
	max-width: 220px;
	margin-bottom: 40px;
	pointer-events: none;
}

.backgroundLogoContainer {
	position: absolute;
	bottom: -20vh;
	left: 0;
	z-index: 0;
	display: flex;
	align-items: center;
	height: 80vh;
	pointer-events: none;
}

.projectLogoBackground {
	margin-bottom: -10%;
	margin-left: -20%;
	opacity: 0.08;
}

@include responsive(largeTablet) {
	.filterSection {
		@include flexCenter(column-reverse);

		.tagsSection {
			min-height: 70px;
			margin: 15px 0;
			padding-right: 0;
		}

		.selectedTags {
			padding: 2px 20px;
			font-size: 0.75rem;
		}

		.filterDropdownButton {
			font-size: 0.75rem;
		}

		.dropdownMenu {
			padding: 5px 10px;

			& label {
				font-size: 0.75rem;
			}
		}

		.tagColor,
		.dropdownClearAllFilters,
		.tagsClearAllFilters {
			font-size: 0.75rem !important;
		}

		.tagsClearAllFilters {
			padding: 5px 20px;
		}
	}
}

@include responsive(extraSmallDevice) {
	.dropdownMenu {
		transform: translate3d(68px, 34px, 0) !important;
	}
}
