@import '/src/utils/sass/mixins.scss';

.navbar {
	padding-top: 70px;
	background-color: $secondary-background;
}

.heroContent {
	height: auto !important;
	padding: 30px 20px;
	pointer-events: all;
}

.caseStudyHeading {
	font-family: $primaryFont;
}

.visitSiteLink {
	@include secondaryButton();
}

@media (max-width: 1200px) {
	.heroContent {
		.caseStudyHeading {
			font-size: 30px !important;
		}

		.sloganHeading {
			font-size: 20px !important;
		}

		.caseStudyText {
			font-size: 14px !important;
		}
	}
}

@media (max-width: 768px) {
	.visitSiteLink {
		margin-top: 20px;
		margin-bottom: 20px;
	}
}

@media (max-width: 576px) {
	.heroContent {
		.caseStudyHeading {
			font-size: 28px !important;
		}

		.sloganHeading {
			font-size: 18px !important;
		}

		.caseStudyText {
			font-size: 12px !important;
		}
	}
}

@media (max-width: 480px) {
	.heroContent {
		.caseStudyHeading {
			margin: 0;
			padding: 0;
			font-size: 26px !important;
			text-align: center;
		}

		.sloganHeading {
			font-size: 16px !important;
		}
	}

	.visitSiteLink {
		align-self: center;
		padding: 10px 70px !important;
	}
}

@media (max-width: 396px) {
	.heroContent {
		.caseStudyHeading {
			font-size: 30px !important;
		}

		.sloganHeading {
			font-size: 16px !important;
		}
	}

	.whiteContainer,
	.blueContainer {
		justify-content: center;

		h4 {
			margin-top: 20px;
			font-size: 25px !important;
		}
	}
}
