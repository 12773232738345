@import '/src/utils/sass/mixins';

.integrationsSection {
	@include flexCenter(column);

	position: relative;
	overflow: hidden;
	min-height: 80vh;
	padding: 100px 20px;
	background-color: $tertiary-background;
	color: #fff;
	font-family: $primaryFont !important;
}

.contentContainer {
	@include flexCenter;

	flex-wrap: wrap;
	width: 80%;
	margin-top: 20px;
}

.backgroundLogo {
	position: absolute;
	right: -100px;
	bottom: -50px;
	width: 400px;
	opacity: 0.1;
	pointer-events: none;
	transform: rotate(-30deg);
}

.logoContainer,
.logo,
.hoverLogo {
	position: relative;
	width: 90px !important;
	height: 90px;
	margin: 30px;
}

.logo,
.hoverLogo {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}

.logo {
	background-color: $tertiary-background;
}

.categoryButtons {
	@include flexCenter;

	z-index: 2;
	flex-wrap: wrap;
	width: 80%;
	margin-bottom: 20px;

	.categoryTab,
	.categoryTabActive {
		@include secondaryButton();
		@include flexCenter;

		margin: 10px;
		padding: 10px 50px;
		background-color: $primary-background !important;

		.categoryName {
			margin: 0 !important;
			padding: 0 10px;
		}
	}

	.categoryTab {
		color: #fff !important;
		font-weight: bold;

		img {
			filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(101deg) brightness(95%) contrast(100%);
		}
	}

	.categoryTabActive,
	.categoryTab:hover {
		background-color: #d7e0ff !important;
		color: #000 !important;

		img {
			filter: invert(100%) sepia(0%) saturate(42%) hue-rotate(0deg) brightness(103%) contrast(103%);
		}
	}
}

@media (max-width: 768px) {
	.logo,
	.logoContainer,
	.hoverLogo {
		width: 12vw !important;
		height: 12vw !important;
		margin-bottom: 0 !important;
	}

	.contentContainer {
		width: 100%;
	}
}

@media (max-width: 675px) {
	.categoryButtons {
		.categoryTab,
		.categoryTabActive {
			width: 70%;
		}
	}
}

@media (max-width: 525px) {
	.logo,
	.logoContainer,
	.hoverLogo {
		width: 20vw !important;
		height: 20vw !important;
		margin-bottom: 0 !important;
	}

	.categoryButtons {
		.categoryTab,
		.categoryTabActive {
			width: 100%;
		}
	}
}

@media (max-width: 396px) {
	.logo,
	.logoContainer,
	.hoverLogo {
		width: 20vw !important;
		height: 20vw !important;
		margin-bottom: 0 !important;
	}

	.categoryButtons {
		.categoryTab,
		.categoryTabActive {
			width: 100%;
		}
	}
}

@media (min-width: 1900px) {
	.integrationsSection {
		min-height: 60vh;
	}
}
