.careersContainer {
	width: 100vw;
	min-height: 100vh;
	color: #fff;
}

.headingSection,
.headingContainer,
.benefitsSection,
.benefits,
.benefit,
.openingsSection,
.positionsContainer {
	display: flex;
	align-items: center;
	justify-content: center;
}

.headingSection {
	overflow: hidden;
	height: 500px;
	padding: 70px 70px 20px;
	background: linear-gradient(rgb(14, 15, 34), rgb(25, 28, 64));
}

.openingsSection {
	position: relative;
	flex-direction: column;
	justify-content: initial;
	padding: 80px 20px;
	background: linear-gradient(rgb(14, 15, 34), rgb(25, 28, 64));
}

.headingContainer {
	flex-direction: column;
	text-align: center;
}

.mainHeading {
	margin-bottom: 20px;
	font-weight: 200;
	font-size: 3em;
}

.joinText,
.benefitsSection h2 {
	font-weight: 300 !important;
}

.astronautLottieContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 400px;
}

.benefitsSection {
	flex-direction: column;
	padding: 100px 20px;
	color: #5a5b5c;
}

.benefits {
	width: 100vw;
}

.benefit {
	flex-direction: column;
	width: 25vw !important;
	margin: 60px 30px 0 !important;
	text-align: center;
	transition: all 0.2s;
}

.benefitIcon {
	height: 30px;
	margin-bottom: 20px;
}

.openingsSection h2 {
	margin-bottom: 10px;
}

.positionsContainer {
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 70vw;
	min-height: 200px;
	margin-top: 50px;
	cursor: none !important;
}

.positionDetailsContainer {
	position: fixed;
	top: 3vh;
	right: -60vw;
	z-index: 99;
	overflow-y: scroll;
	width: 50vw;
	height: 94vh;
	padding: 80px 70px 80px 60px;
	border-radius: 20px;
	background: #fff;
	color: #555;
	-webkit-box-shadow: -1px 3px 5px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: -1px 3px 5px 0 rgba(0, 0, 0, 0.1);
	box-shadow: -1px 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.closeDetailsBtn {
	position: absolute;
	top: 20px;
	left: 20px;
	width: 30px;
	height: 30px;
	border: none;
	border-radius: 50%;
	background: rgb(190, 190, 190);
	outline: none;
	cursor: none !important;
}

.closeDetailsBtn span {
	color: #fff;
	font-weight: bold;
	font-size: 22px;
	line-height: 22px;
}

.detailsTag {
	color: #777;
}

.applyBtn {
	padding: 7px 25px;
	border: none;
	border-radius: 5px;
	background: #d59607;
	color: #fff;
	-webkit-box-shadow: -1px 3px 5px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: -1px 3px 5px 0 rgba(0, 0, 0, 0.1);
	box-shadow: -1px 3px 5px 0 rgba(0, 0, 0, 0.1);
	outline: none;
	font-weight: bold;
	cursor: none !important;
}

.tagsContainer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 30vw;
	margin-top: 20px;
}

.tagsContainer button {
	margin: 5px;
	padding: 5px 10px;
	border: none;
	border-radius: 5px;
	color: #fff;
	outline: none !important;
	cursor: none !important;
}

.tagsContainer button:nth-child(1) {
	background: #c40c4f;
}

.designBtn,
.tagsContainer button:nth-child(2) {
	background: #811180;
}

.developmentBtn,
.tagsContainer button:nth-child(3) {
	background: #116481;
}

.tagsContainer button:nth-child(4) {
	background: #d59607;
}

@media (max-width: 992px) {
	.benefit {
		width: 40vw;
	}

	.positionsContainer {
		width: 90vw;
	}
}

@media (max-width: 768px) {
	.positionDetailsContainer {
		right: -100vw;
		width: 90vw;
	}

	.tagsContainer {
		width: 70vw;
	}
}

@media (max-width: 576px) {
	.benefit {
		width: 70vw;
		margin: 40px 10px 0;
	}

	.headingContainer,
	.astronautLottieContent {
		flex: 0 0 80% !important;
		max-width: 80% !important;
	}

	.astronautLottieContainer {
		height: 250px;
	}

	.headingSection {
		height: 750px;
		padding: 120px 70px 50px;
	}

	.mainheading {
		font-size: 8vw;
	}
}
