@import '/src/utils/sass/mixins';

.mainContainer {
	display: flex;
	align-items: center;
	overflow: hidden;
	width: 100%;
	height: 600px;
	background: $secondary-background;
	color: #fff;
}

.imageContainer {
	overflow: hidden;
	width: 45%;

	img {
		object-fit: cover;
		min-width: 100%;
		min-height: 100%;
		object-position: center;
	}
}

.contentContainer {
	width: 55%;
	padding: 70px;

	p {
		padding: 0;
	}
}

@media (max-width: 992px) {
	.mainContainer {
		flex-wrap: wrap;
		height: fit-content;
	}

	.imageContainer,
	.contentContainer {
		overflow: hidden;
		width: 100%;
		height: 500px;
	}

	.contentContainer {
		height: fit-content;
		text-align: center;
	}
}
