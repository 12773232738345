@import '/src/utils/sass/mixins';

.footer {
	width: 100vw;
	padding: 30px 40px 0;
	background-color: $secondary-background;
	color: #fff;
}

.footerContent {
	margin: 40px 10px !important;
}

.link {
	width: fit-content;
	margin-bottom: 8px;
	margin-left: 25px;
	color: #fff !important;
	text-decoration: none;
	cursor: none !important;
}

.linksContainer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.logo {
	height: 24px;
	margin: 15px 0;
}

.footerText {
	max-width: 450px;
	margin-top: 10px;
}

@media (max-width: 992px) {
	.linksContainer,
	.logoContainer {
		justify-content: center !important;
		text-align: center !important;
	}

	.linksContainer {
		justify-content: initial !important;
	}

	.footerText {
		margin: 40px auto 0;
	}

	.footerContent {
		margin: 40px 0 !important;
	}

	.link {
		margin-left: 0;
	}

	.logo {
		max-width: 75vw;
		margin: 0;
	}
}
