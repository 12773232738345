.cursor {
	position: fixed;
	z-index: 1000000;
	pointer-events: none;
	transition: fill 0.2s;
	transform: translate(-15px, -15px);
}

.cursorPointer,
.disabledCursor {
	transition: all 0.2s;
}

@media (max-width: 576px) {
	.cursor {
		visibility: hidden !important;
	}
}
