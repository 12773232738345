@import '/src/utils/sass/mixins';

.mainContainer {
	@include flexCenter(column);

	position: relative;
	padding: 100px 0;
	background-color: #fff;
}

.techHireBlock {
	min-width: 100%;
	height: 100%;
	min-height: 600px;
	padding: 30px;
	background: linear-gradient(to bottom, $tertiary-background, #111126);
}

.techHireHeading {
	position: absolute;
	bottom: 0;
	left: 0;
	margin-bottom: 40px;
	margin-left: 35px;
	color: #fff;
	font-weight: bold;
	font-size: 68px !important;
	text-align: left;
}

.moonImage {
	position: absolute;
	top: 0;
	right: 0;
	width: 200px;
	height: 200px;
	margin: 50px;
}

.solutionBlock {
	@include flexCenter(column);

	height: 100%;
	min-height: 250px;
	padding: 25px;
	background-color: $tertiary-background;
}

.solutionHeading {
	margin-bottom: 20px;
	color: #fff;
	font-weight: bold;
	text-align: center;
}

.solutionContent {
	color: #fff;
	text-align: center;
}

.weOfferBlock {
	@include flexCenter(row);

	display: flex;
	width: 100%;
	padding: 35px;
	background-color: #77b0ff;

	.weOfferText {
		display: inline-block;
		flex-direction: column;
		color: #fff;
		font-weight: bold;
		font-size: 50px !important;
		text-align: center;
	}
}

.offeringContainer {
	display: inline-block;
	overflow: hidden;
	width: 410px;
	height: 70px;

	.offeringText {
		position: relative;
		color: #000;
		font-weight: bold;
		text-align: center;
	}

	.offeringText span {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		overflow: hidden;
		margin-left: 10px;
		padding-bottom: 10px;
		font-size: 50px;
		animation: scrollup 16s infinite both;
	}
}

.offeringText span:nth-child(2) {
	animation-delay: 4s;
}

.offeringText span:nth-child(3) {
	animation-delay: 8s;
}

.offeringText span:nth-child(4) {
	animation-delay: 12s;
}

@keyframes scrollup {
	0% {
		opacity: 0;
		transform: translate3d(0, 0, 0);
	}

	20% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	40%,
	60% {
		opacity: 0;
		transform: translate3d(0, -50px, 0);
	}

	80%,
	100% {
		opacity: 0;
		transform: translate3d(0, -100px, 0);
	}
}

@media (min-width: 1200px) and (max-width: 1400px) {
	.techHireHeading {
		font-size: 50px !important;
	}

	.solutionHeading {
		font-size: 22px !important;
	}

	.solutionContent {
		font-size: 18px !important;
	}

	.weOfferBlock .weOfferText {
		font-size: 36px !important;
	}

	.offeringContainer {
		width: 300px;
		height: 53px;
	}

	.offeringText span {
		margin-left: 8px;
		font-size: 36px !important;
	}
}

@media (min-width: 980px) and (max-width: 1200px) {
	.techHireHeading {
		font-size: 50px !important;
	}

	.solutionHeading {
		font-size: 22px !important;
	}

	.solutionContent {
		font-size: 18px !important;
	}

	.weOfferBlock .weOfferText {
		font-size: 30px !important;
	}

	.offeringContainer {
		width: 300px;
		height: 45px;
	}

	.offeringText span {
		margin-left: 8px;
		font-size: 30px !important;
	}
}

@media (min-width: 768px) and (max-width: 980px) {
	.techHireHeading {
		font-size: 50px !important;
	}

	.solutionHeading {
		font-size: 22px !important;
	}

	.solutionContent {
		font-size: 18px !important;
	}

	.weOfferBlock .weOfferText {
		font-size: 36px !important;
	}

	.offeringContainer {
		width: 300px;
		height: 53px;
	}

	.offeringText span {
		margin-left: 8px;
		font-size: 36px !important;
	}
}

@media (min-width: 480px) and (max-width: 768px) {
	.mainContainer {
		padding: 50px 0;
	}

	.techHireBlock {
		min-height: 400px;
	}

	.techHireHeading {
		margin-bottom: 20px;
		font-size: 38px !important;
	}

	.moonImage {
		width: 150px;
		height: 150px;
		margin: 30px;
	}

	.solutionBlock {
		width: 100%;
		height: 100%;
		margin: 20px 0;
		padding: 15px;
	}

	.weOfferBlock {
		padding: 30px 10px;

		.weOfferText {
			font-size: 30px !important;
		}
	}

	.offeringContainer {
		width: 250px;
		height: 45px;
	}

	.offeringText span {
		font-size: 30px !important;
	}
}

@media (max-width: 480px) {
	.mainContainer {
		padding: 50px 0;
	}

	.techHireBlock {
		min-height: 450px;
		margin: -30px 0;
	}

	.techHireHeading {
		margin-bottom: 20px;
		font-size: 38px !important;
	}

	.moonImage {
		width: 150px;
		height: 150px;
		margin: 30px;
	}

	.solutionBlock {
		margin: 3px 0;
	}

	.offeringContainer {
		width: 170px;
		height: 32px;
	}

	.offeringText span {
		font-size: 20px !important;
	}

	.weOfferBlock {
		margin: -10px 0;
		padding: 15px 5px;

		.weOfferText {
			margin-top: 1px;
			font-size: 20px !important;
		}
	}
}
