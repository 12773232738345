@import '/src/utils/sass/mixins';

.mainContainer {
	width: 100%;
	min-width: 280px;
	height: 100vh;
	min-height: 500px;
	padding-top: 50px;
	background: linear-gradient($primary-background, $secondary-background);
}

.errorScreenContainer {
	@include flexCenter(column);

	top: calc(50% - 20vh) !important;
	width: 100%;
	min-height: 100vh;
	margin-top: 50;
	color: #fff;
	pointer-events: all;

	.errorIllustration {
		width: 500px;
		height: 300px;
	}

	.errorMessageContainer {
		@include flexCenter(column);

		display: flex;
		font-family: $primaryFont;
		text-align: center;
	}

	.backHomeButton {
		@include secondaryButton();

		margin-top: 50px;
		margin-bottom: 50px;
	}
}

@media (max-width: 768px) {
	.errorScreenContainer {
		top: calc(50% - 15vh) !important;

		.errorMessageContainer h2 {
			padding: 0 20px;
			font-size: 28px !important;
		}

		.errorIllustration {
			width: 400px;
			height: 200px;
		}

		.backHomeButton {
			margin: 20px 0;
			padding: 10px 50px !important;
			font-size: 12px !important;
		}
	}
}

@media (max-width: 576px) {
	.errorScreenContainer {
		top: calc(50% - 15vh) !important;

		.errorMessageContainer h2 {
			padding: 0 20px;
			font-size: 24px !important;
		}

		.errorIllustration {
			width: 250px;
			height: 150px;
		}

		.backHomeButton {
			margin: 20px 0;
			padding: 10px 50px !important;
			font-size: 12px !important;
		}
	}
}
